import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IJournalIndicatorsItem } from "./types";

interface JournalIndicatorsState {
  /*   invalidationKey: number;
  loadings: Record<string, boolean>; */
  indicators: Record<string, IJournalIndicatorsItem[]>;
}

export const initialState: JournalIndicatorsState = {
  /* invalidationKey: 0,
  loadings: {}, */
  indicators: {},
};

const journalIndicatorsSlice = createSlice({
  name: "journalIndicators",
  initialState,
  reducers: {
    /* invalidateKey: (state) => {
      state.invalidationKey++;
    },
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    }, */
    setIndicators: (state, action: PayloadAction<{ data: IJournalIndicatorsItem[]; key: string }>) => {
      const { data, key } = action.payload;
      state.indicators[key] = data;
    },
  },
});

export const journalIndicatorsActions = journalIndicatorsSlice.actions;

export const journalIndicatorsReducer = journalIndicatorsSlice.reducer;
