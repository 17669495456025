import { Dispatch } from "redux";

import { journalIndicatorsApi } from "./api";
import { journalIndicatorsActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

export const getJournalIndicators = (objectId: string, year: number, month: number) => (dispatch: Dispatch) => {
  const key = generateStorageKey({ objectId, month, year });
  //loading
  journalIndicatorsApi.get(objectId, { month, year }).then(({ data }) => {
    dispatch(journalIndicatorsActions.setIndicators({ key, data }));
  });
};
