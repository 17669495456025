import cn from "classnames";
import React from "react";

import {
  ISectionInJournalExec,
  ISectionInJournalFulfillment,
} from "redux/modules/common/building/journal/journalExecution/types";

import { useUIIndicator } from "../../../../../../../../hooks/uiIndicators/useUIIndicator";
import { JournalIndicatorsUI } from "components/pages/Journal/features/JournalIndicators";

import TableReusableRow, { TableReusableCell } from "shared/ui/dataDisplay/TableReusable/TableReusableRow";

import { MODULES_ENUM } from "../../../../../../../../types/enums/ModulesEnum";

import commonJournalStyles from "../../../../../Journal.module.scss";
import styles from "./JournalExecSection.module.scss";

interface IProps {
  onOpenSection?: (sectionId: number) => void;
  section: ISectionInJournalFulfillment;
}

const JournalExecSection: React.FC<IProps> = ({ onOpenSection, section }) => {
  const { isIndicatorDisplayed } = useUIIndicator({
    data: { child_section_id: section.id },
    module: MODULES_ENUM.OBJECTS,
    //@ts-ignore
    type: ["remark_created", "remark_review"],
  });

  const clickHandler = React.useCallback(
    (id: number) => {
      onOpenSection?.(id);
    },
    [onOpenSection]
  );

  return (
    <>
      <TableReusableRow
        containerClassName={styles.parent}
        className={cn(styles.name, commonJournalStyles.sectionParentRow)}
        isHoverable={false}
      >
        <TableReusableCell isNoBreak>{section.name}</TableReusableCell>
      </TableReusableRow>
      {section.subsections.map((el) => {
        return (
          <TableReusableRow
            key={el.id}
            className={styles.row}
            onClick={() => clickHandler(el.id)}
            isWithIndicator={isIndicatorDisplayed}
          >
            <TableReusableCell isNoBreak>{el.name}</TableReusableCell>
            <JournalIndicatorsUI.Section section_id={el.id} tab="not_fulfillment" />
          </TableReusableRow>
        );
      })}
    </>
  );
};

export default JournalExecSection;
