import React, { useMemo } from "react";

import { useJournalIndicatorsContext } from "../contexts/JournalIndicatorsContext";
import { useTypedSelector } from "app/store/typedUseSelector";

import { IJournalIndicatorsItem } from "../model/types";

import { generateStorageKey } from "utils/helpers/generateStorageKey";

import styles from "./JournalIndicator.module.scss";

interface ITabProps {
  tab: Exclude<keyof IJournalIndicatorsItem, "section_id">;
}

const Tab = ({ tab }: ITabProps) => {
  const { objectId, month, year } = useJournalIndicatorsContext();
  const key = generateStorageKey({ objectId, month, year });
  const indicators = useTypedSelector((state) => state.journalIndicatorsReducer.indicators)[key];

  const showIndicator = useMemo(() => {
    if (!indicators) return false;
    return indicators.some((el) => el[tab]);
  }, [indicators, tab]);

  if (!showIndicator) return <div className={styles.tabPlaceholder} />;

  return <div className={styles.tab} />;
};

interface ISectionProps {
  tab: Exclude<keyof IJournalIndicatorsItem, "section_id">;
  section_id: number;
}

const Section = ({ tab, section_id }: ISectionProps) => {
  const { objectId, month, year } = useJournalIndicatorsContext();
  const key = generateStorageKey({ objectId, month, year });
  const indicators = useTypedSelector((state) => state.journalIndicatorsReducer.indicators)[key];

  const count = useMemo(() => {
    if (!indicators) return;
    return indicators.find((el) => el.section_id === section_id)?.[tab];
  }, [indicators, tab]);

  if (!count) {
    return null;
  }

  return <div className={styles.section}>{count}</div>;
};

export const JournalIndicatorsUI = { Tab, Section };
