import React, { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actingInvalidateKeySelector } from "redux/modules/common/building/journal/acting/selectors";
import { invalidateKeyInJournalExecSelector } from "redux/modules/common/building/journal/journalExecution/selectors";
import { journalTicketsInvalidateKeySelector } from "redux/modules/common/building/journal/selectors";

import { getJournalIndicators } from "../model/thunks";

type JournalIndicatorsContextType = { year: number; month: number; objectId: string };

const JournalIndicatorsContext = createContext<JournalIndicatorsContextType>({ year: 0, month: 0, objectId: "" });

export const JournalIndicatorsContextProvider = ({
  children,
  year,
  month,
  objectId,
}: PropsWithChildren & { year: number; month: number; objectId: string }) => {
  const dispatch = useDispatch();
  //TODO ключи со всех табов ЖУ чтоб перезапрашивать индикаторы после любого изменения данных
  const key1 = useSelector(journalTicketsInvalidateKeySelector);
  const key2 = useSelector(invalidateKeyInJournalExecSelector);
  const key3 = useSelector(actingInvalidateKeySelector);

  useEffect(() => {
    dispatch(getJournalIndicators(objectId, year, month + 1));
  }, [objectId, year, month, key1, key2, key3]);

  return (
    <JournalIndicatorsContext.Provider value={{ year, month, objectId }}>{children}</JournalIndicatorsContext.Provider>
  );
};

export const useJournalIndicatorsContext = () => useContext(JournalIndicatorsContext);
